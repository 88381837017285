@primary-color: #39628b;
@sidebar-width: 230px;

@white: #fff;


html, body {
  position: relative;
  height: 100%;
}

#app, .main-wrapper {
  height:100% !important;
}

.auth-wrapper {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  background-color: hsla(0,0%,100%,1);
  background-image: radial-gradient(at 13% 99%, hsla(258,0%,100%,1) 0px, transparent 50%), radial-gradient(at 72% 96%, hsla(32,0%,100%,1) 0px, transparent 50%), radial-gradient(at 87% 63%, hsla(122,0%,100%,1) 0px, transparent 50%), radial-gradient(at 49% 55%, hsla(126,0%,100%,1) 0px, transparent 50%), radial-gradient(at 51% 14%, hsla(202,0%,100%,1) 0px, transparent 50%), radial-gradient(at 13% 5%, hsla(242,0%,100%,1) 0px, transparent 50%), radial-gradient(at 37% 27%, hsla(323,100%,41%,1) 0px, transparent 50%), radial-gradient(at 11% 36%, hsla(225,73%,22%,1) 0px, transparent 50%), radial-gradient(at 85% 48%, hsla(323,100%,41%,1) 0px, transparent 50%), radial-gradient(at 84% 37%, hsla(225,73%,22%,1) 0px, transparent 50%);

  & .logo-container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 25px;

    & img {
      max-height: 30px;
    }
  }

  & .form-container {
    width: 500px;
    border-radius: 15px;
    padding: 10px;
    border: 0;

    box-shadow: rgba(0, 0, 0, 0.2) 0 18px 50px -10px;

    &.login {
      max-width: 350px;
    }
  }

  & .terms-container {
    max-height: 500px;
    overflow-y: scroll;
    margin-bottom: 10px;
  }
}

.header-logo {
  height: 50px;
  background: rgba(15,36,99,1);

  & img {
    width: 90%;
    margin: 5px 0 0 10px;
  }

  &.collapsed {
    & img {
      height: 35px;
      width: 35px;
      margin: 7px 20px 3px 20px;
    }
  }
}

.header {
  z-index: 0;
  background: rgb(15,36,99);
  background: linear-gradient(90deg, rgba(15,36,99,1) 0%, rgba(15,36,99,1) 65%, rgba(33,64,158,1) 100%);
  padding: 0;

  .sidemenu-collapse {
    padding: 0 5px;
  }

  & .header-search {
    & input {
      width: 100%;
    }
  }

  & .header-actions {
    text-align: left;
    color: #fff;

    & .ant-row {
      display: flex;
      align-items: center;

      & .ant-col {
        display: flex;
        align-items: center;

        & .ant-btn {
          line-height: 1;
          text-align: left;
          padding: 0;
          margin-left: 10px;

          & .partnership-badge {
            color: gold;
            padding: 8px 5px;
          }
        }

        & .ant-btn-text {
          color: #fff;
        }
      }
    }
  }
}

.main {
  overflow-y: scroll;
  background-color: #f9f9f9;

  & .main-header {
    background-color: #f1f2f4;
    padding-right: 10px;
  }

  & .main-header-text {
    padding: 10px;
    font-size: 17px;
    font-weight: bold;
  }

  & .main-content {
    padding: 10px;

    & .ant-card {
      box-shadow: 0 9px 25px -15px rgba(0,0,0,0.1);
    }
  }
}

.sidebar {
  z-index: 100;
}

.ant-layout-header {
  height: 50px;
  line-height: 3;
}

.sidemenu-collapse {
  color: #fff;
  font-size: 16px;
}

@media only screen and (min-width: 46.875em) {
  .sidebar {
    width: @sidebar-width;
    box-shadow: 10px 0 15px -10px rgba(0,0,0,0.1);

    & .sidebar-content {
      height: 100%;
      display: flex;
      flex-direction: column;

      & .sidebar-menu-area {
        flex-grow: 1;
      }
    }
  }

  .header {
    z-index: 100;

    padding-left: 0;

    & .header-search {
      & input {
        width: 400px;
      }
    }

    & .header-user-info {
      line-height: 1;
      font-size: 14px;
      text-align: left;
      color: #fff;

      &:hover {
        color: #fff !important;
      }

      & small {
        font-size: 11px;
      }
    }
  }

  & .header-logo {
    align-items: center;
    justify-content: center;
    display: flex;

    & img {
      height: 30px;
      width: inherit;
    }

    &.collapsed {
      & img {
        height: 35px;
      }
    }
  }
}

.account-manager-title,
.support-manager-title {
  font-size: 13px;
}

.account-manager-avatar {
  margin-bottom: 10px;

  & .text {
    color: #fff;
  }
}

.account-manager-data,
.support-manager-data {
  word-break: break-word;
  font-size: 11px;
  line-height: 1;
  color: #595959;

  & .icon {
    margin-bottom: -2px;
  }
}

@and-bg-color: #fffdf4;
@and-border-color: darken(@and-bg-color, 20%);
@and-border-style: dashed;
@or-bg-color: #f9f9f9;
@or-border-color: darken(@or-bg-color, 10%);

.logic-block {
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;

  &.and {
    background-color: @and-bg-color;
    border: 2px @and-border-style @and-border-color;
  }

  &.or {
    background-color: @or-bg-color;
    border: 1px solid @or-border-color;
  }
}

.logic-block-separator {
  border-left: 1px solid #ccc;
  padding: 17px 0;
  position: relative;
  margin-left: 30px;
  display: inline-block;

  & span {
    background-color: #ccc;
    margin-left: calc(-1 * (100% / 2));
    padding: 5px;
    font-size: 12px;
    border-radius: 5px;
  }

  &.and {
    border-left: 2px @and-border-style @and-border-color;

    & span {
      background-color: @and-bg-color;
      border: 2px @and-border-style @and-border-color;
    }
  }

  &.or {
    border-left: 1px solid @or-border-color;

    & span {
      background-color: @or-bg-color;
      border: 1px solid @or-border-color;
    }
  }
}

.color-picker-choice {
  width: 30px;
  height: 30px;
  border-radius: 5px;
  border: 1px solid #d7d7d7;
}

.notifications-box {
  width: 350px;
  max-height: 400px;
  overflow-y: scroll;
  overflow-x: hidden;
}


/* Utilities */

.text-right {
  text-align: right;
}

.text-left {
  text-align: left;
}

.text-center {
  text-align: center;
}

strong {
  font-weight: 600;
}

.ant-table-thead > tr > th {
  font-weight: 600;
}

.domain-notes {
  color: lightgray;
  cursor: pointer;
}

@dark-bg: #1e1e1e;
@dark-component-bg: lighten(@dark-bg, 5%);
@dark-text: #fff;
@dark-component-text: #fff;

/*
.dark {
  & main {
    background-color: @dark-bg;
  }

  & .main-header {
    background-color: @dark-bg;

    & .main-header-text {
      color: @dark-text;
    }

  }

  & .sidebar {
    background-color: @dark-bg;

    & .ant-menu {
      background-color: @dark-bg;
      color: @dark-text;
    }
  }

  & .ant-card {

    &.ant-card-bordered {
      border-color: lighten(@dark-component-bg, 3%);
    }

    background-color: @dark-component-bg;
    color: @dark-component-text;
  }
}*/

.driver-popover.pp-theme {
}

.driver-popover.pp-theme .driver-popover-title {
  font-size: 20px;
}

.driver-popover.pp-theme .driver-popover-title,
.driver-popover.pp-theme .driver-popover-description,
.driver-popover.pp-theme .driver-popover-progress-text {
  color: #000;
}

.driver-popover.pp-theme .driver-popover-description {
  font-size: 14px;
}

.driver-popover.pp-theme button:not(.driver-popover-close-btn) {
  flex: 1;
  text-align: center;
  background-color: @primary-color;
  color: #ffffff;
  text-shadow: none;
  font-size: 15px;
  padding: 6px 10px;
  border-radius: 5px;
  border: 1px solid @primary-color;
}

.driver-popover.pp-theme button:hover {
  background-color: lighten(@primary-color, 5%);
  color: #ffffff;
}

.driver-popover.pp-theme .driver-popover-navigation-btns {
  justify-content: space-between;
  gap: 3px;
}

.driver-popover.pp-theme .driver-popover-close-btn {
  color: #9b9b9b;
}

.driver-popover.pp-theme .driver-popover-close-btn:hover {
  color: #000;
}

.driver-popover.pp-theme .driver-popover-arrow-side-left.driver-popover-arrow {
  border-left-color: #fff;
}

.driver-popover.pp-theme .driver-popover-arrow-side-right.driver-popover-arrow {
  border-right-color: #fff;
}

.driver-popover.pp-theme .driver-popover-arrow-side-top.driver-popover-arrow {
  border-top-color: #fff;
}

.driver-popover.pp-theme .driver-popover-arrow-side-bottom.driver-popover-arrow {
  border-bottom-color: #fff;
}


.help-button-arrow {
  animation: bounce 1s infinite;
  color: #ff4d4f;
  font-size: 2em;
}

@keyframes bounce {
  0%, 100% {
    transform: translateX(0);
  }
  50% {
    transform: translateX(-10px);
  }
}

.getting-started-wizard-floater {
  animation: shake 0.5s ease;
  transform-origin: center center;
}

@keyframes shake {
  0% { transform: rotate(0deg); }
  20% { transform: rotate(6deg); }
  40% { transform: rotate(-5deg); }
  60% { transform: rotate(5deg); }
  80% { transform: rotate(-3deg); }
  100% { transform: rotate(0deg); }
}
@primary-color: #0f2463;@border-radius-base: 5px;@alert-info-bg-color: #e6f7ff;@alert-info-border-color: #91d5ff;@alert-info-icon-color: #1890ff;@layout-sider-background: #fff;@menu-item-active-bg: #fafafa;@select-item-active-bg: #fafafa;@select-selection-item-bg: #fafafa;@primary-1: #fafafa;